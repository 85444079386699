//STUDENTS
export const FETCH_ALL_STUDENTS = "FETCH_ALL_STUDENTS";
export const FETCH_ALL_STUDENTS_SF10 = "FETCH_ALL_STUDENTS_SF10";
export const CREATE_STUDENT = "CREATE_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const FILTER_STUDENT = "FILTER_STUDENT";
export const FILTER_STUDENT_SF10 = "FILTER_STUDENT_SF10";
export const SEARCH_STUDENT = "SEARCH_STUDENT";
export const SEARCH_STUDENT_SF10 = "SEARCH_STUDENT_SF10";
export const UPDATE_STUDENT_REMARKS = "UPDATE_STUDENT_REMARKS";
export const GET_SF1_STUDENTS = "GET_SF1_STUDENTS";
export const GET_SF10_STUDENT = "GET_SF10_STUDENT";
export const START_LOADING = "START_LOADING";
export const END_LOADING_START = "END_LOADING_START";
export const END_LOADING = "END_LOADING";
export const CLOSE_ALERT = "CLOSE_ALERT";

//FACULTY
export const FETCH_ALL_FACULTY = "FETCH_ALL_FACULTY";
export const CREATE_FACULTY = "CREATE_FACULTY";
export const DELETE_FACULTY = "DELETE_FACULTY";
export const UPDATE_FACULTY = "UPDATE_FACULTY";
export const FILTER_FACULTY = "FILTER_FACULTY";
export const SEARCH_FACULTY = "SEARCH_FACULTY";
export const GET_SF1_TEACHER = "GET_SF1_TEACHER";
export const GET_HEAD_INFO = "GET_HEAD_INFO";
export const UPDATE_HEAD = "UPDATE_HEAD";
export const UPDATE_FACULTY_SETTINGS = "UPDATE_FACULTY_SETTINGS";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

//GRADE
export const GET_GRADES = "GET_GRADES";
export const UPDATE_GRADE = "UPDATE_GRADE";
export const CLEAR_GRADE = "CLEAR_GRADE";
export const SET_STUDENT_SF9INFO = "SET_STUDENT_SF9INFO";
export const SET_STUDENT_RANKING = "SET_STUDENT_RANKING";
export const SET_STUDENT_QUARTERLY = "SET_STUDENT_QUARTERLY";


//BOOK
export const ADD_BOOK = "ADD_BOOK";
export const FETCH_ALL_STUDENTS_BOOK = "FETCH_ALL_STUDENTS_BOOK";
export const FILTER_STUDENT_BOOKS = "FILTER_STUDENT_BOOKS";
export const UPDATE_BOOK = "UPDATE_BOOK";
export const BOOK_HEADER = "BOOK_HEADER";
export const BOOK_TITLE = "BOOK_TITLE";




